Strophe.addConnectionPlugin('markers', {
    _conn: null,
    _msgQueue: {},
    //_retries: {},
    //_resendCount: 10,
  //  _resendTime: 9000,


    init: function(conn) {
        //console.log('here markers');
        this._conn = conn;
        Strophe.addNamespace('CHAT_MARKERS', "urn:xmpp:chat-markers:0");
        //Strophe.NS.RECEIPTS = 'urn:xmpp:chat-markers:0';
    },

    sendChatMarker: function(to, from, originalMessageId) {
      console.log("here send markers");
        var chatMarker = $msg({
          to: to,
          from: from,
          id: "<id>"
        })
          .c('markable', {
            xmlns: "urn:xmpp:chat-markers:0",
            id: originalMessageId
          })
          .up().c("store", {
            xmlns:"urn:xmpp:archive"
          });

        this._conn.send(chatMarker);
        console.log(chatMarker);
    },

    sendDeliveredMarker: function(to, from, originalMessageId) {
        console.log('here send marker');
        var stanzaParams = {
            type: 'chat',
            from: from,
            id: originalMessageId,
            to: to
        };

      var messageStanza = $msg(stanzaParams);
          messageStanza
              .c('received', {
                  xmlns: "urn:xmpp:chat-markers:0",
                  id: originalMessageId
              })
              .up();

          console.log(messageStanza);
          this._conn.send(messageStanza);
      },

      sendReadMarker: function(to, from, originalMessageId) {
          var stanzaParams = {
              type: 'chat',
              from: from,
              id: "<id>",
              to: to
          };

          var messageStanza = $msg(stanzaParams);
          messageStanza
              .c('displayed', {
                  xmlns: "urn:xmpp:chat-markers:0",
                  id: originalMessageId
              })
              .up();

          this._conn.send(messageStanza.tree());
      }
});
